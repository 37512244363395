<template>
  <a-modal
    title="未盘点库位货品"
    :width="1280"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="cancel"
  >
    <template #footer>
      <a-button key="back" @click="cancel">取消</a-button>
      <a-button key="submit" type="primary" @click="toOk">应用到盘点差异</a-button>
    </template>
    <a-spin :spinning="loading">
      <div>
        <s-table
          ref="table"
          size="small"
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :data="loadData"
        >
        </s-table>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import { wms_warehouse_take_inventory_check_uninventory_diff } from '@/api/c_wms_period_inventory'

export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      // 表头
      columns: [
        {
          title: '库位',
          dataIndex: 'cargo_space',
          align: 'center',
          ellipsis: true
        },
        {
          title: '商品',
          dataIndex: 'goods_name',
          align: 'center',
          ellipsis: true
        },
        {
          title: '商品条形码',
          dataIndex: 'goods_purchase',
          align: 'center',
          ellipsis: true
        },
        {
          title: '实际数量',
          dataIndex: 'current_count',
          align: 'center',
          ellipsis: true
        },
        {
          title: '盘余数量',
          align: 'center',
          dataIndex: 'diff_count'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return wms_warehouse_take_inventory_check_uninventory_diff({ take_inventory_id: this.model.id })
          .then(res => {
            this.dataSource = res.data
            return {
              entries: this.dataSource
            }
          })
      }
    }
  },
  methods: {
    toOk () {
      this.$emit('ok', this.model.id, this.dataSource)
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
