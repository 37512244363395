<template>
  <a-modal
    title="差异列表"
    :width="1280"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="cancel"
  >
    <template #footer>
      <a-button key="back" @click="cancel">取消</a-button>
      <a-button key="abolish" @click="toabolish" v-if="false">废除差异</a-button>
      <a-button key="submit" type="primary" @click="toOk">应用差异</a-button>
    </template>
    <a-spin :spinning="loading">
      <div>
        <div class="table-page-search-wrapper"></div>
        <s-table
          ref="table"
          size="small"
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :data="loadData"
        >
        </s-table>
      </div>
    </a-spin>
    <check-diff-list-form
      v-if="checkVisible"
      ref="CheckDiffListForm"
      :visible="checkVisible"
      :loading="checkConfirmLoading"
      :model="check_mdl"
      @cancel="handleCheckDiffCancel"
      @ok="handleCheckOk"
    />
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import {
  wms_warehouse_take_inventory_confirm_diff_list,
  wms_warehouse_take_inventory_check_uninventory_diff,
  wms_warehouse_take_inventory_check_uninventory_diff_create
} from '@/api/c_wms_period_inventory'
import CheckDiffListForm from './CheckDiffListForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CheckDiffListForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataSource: [],
      checkVisible: false,
      checkConfirmLoading: false,
      check_mdl: {},
      loading: false,
      // 表头
      columns: [
        {
          title: '库位',
          dataIndex: 'cargo_space',
          align: 'center',
          ellipsis: true
        },
        {
          title: '商品',
          dataIndex: 'goods_name',
          align: 'center',
          ellipsis: true
        },
        {
          title: '商品条形码',
          dataIndex: 'goods_purchase',
          align: 'center',
          ellipsis: true
        },
        {
          title: '实际数量',
          dataIndex: 'current_count',
          align: 'center',
          ellipsis: true
        },
        {
          title: '盘余数量',
          align: 'center',
          dataIndex: 'diff_count'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return wms_warehouse_take_inventory_confirm_diff_list({ take_inventory_id: this.model.id })
          .then(res => {
            const data = res.data.entries
            // const mydata = []
            // const goods_purchase = []
            // data.forEach(item => {
            //   const index = goods_purchase.indexOf(item.goods_purchase + '-' + item.cargo_space_id)
            //   if (index > 0) {
            //     mydata[index].available_count += item.available_count
            //     mydata[index].diff_count = (Number(mydata[index].diff_count) + Number(item.diff_count)).toFixed(4)
            //   } else {
            //     mydata.push(item)
            //     goods_purchase.push(item.goods_purchase + '-' + item.cargo_space_id)
            //   }
            // })
            return {
              entries: data
            }
          })
      }
    }
  },
  methods: {
    toOk () {
      // this.$emit('ok', this.model.id, true)
      this.check()
    },
    toabolish () {
      this.$emit('ok', this.model.id, false)
    },
    cancel () {
      this.$emit('cancel')
    },
    handleCheckDiffCancel () {
      this.checkVisible = false
    },
    handleCheckOk (id, result) {
      wms_warehouse_take_inventory_check_uninventory_diff_create({
        take_inventory_id: id,
        take_inventory_results: result
      }).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    check () {
      wms_warehouse_take_inventory_check_uninventory_diff({ take_inventory_id: this.model.id }).then(res => {
        if (res.code === 1000) {
          if (res.data !== undefined) {
            if (res.data.length > 0) {
              this.check_mdl.id = this.model.id
              this.checkVisible = true
            } else {
              this.$emit('ok', this.model.id, true)
            }
          }
        }
      })
    }
  }
}
</script>
