import request from '@/utils/request'
const requestApi = {
  wms_warehouse_list: '/wms/warehouse/',
  wms_warehouse_create: '/wms/warehouse/',
  wms_warehouse_update: '/wms/warehouse/',
  wms_warehouse_delete: '/wms/warehouse/',
  warehouse_list: '/wms/warehouse_id/',
  wms_common_warehouse_outflow_list: '/wms/common_warehouse/outflow/',
  wms_common_warehouse_outflow_create: '/wms/common_warehouse/outflow/',
  wms_common_warehouse_outflow_partial_update: '/wms/common_warehouse/outflow/',
  wms_common_warehouse_outflow_delete: '/wms/common_warehouse/outflow/'
}

export function warehouse_list (query) {
  return request({
    url: requestApi.wms_warehouse_list,
    method: 'get',
    params: query
  })
}
export function warehouse_list_id (query) {
  return request({
    url: requestApi.warehouse_list,
    method: 'get',
    params: query
  })
}

export function warehouse_create (data) {
  return request({
    url: requestApi.wms_warehouse_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function warehouse_update (data, warehouse_id) {
  return request({
    url: requestApi.wms_warehouse_update + warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function warehouse_delete (warehouse_id) {
  return request({
    url: requestApi.wms_warehouse_delete + warehouse_id + '/',
    method: 'delete'
  })
}
export function wms_common_warehouse_outflow_list (query) {
  return request({
    url: requestApi.wms_common_warehouse_outflow_list,
    method: 'get',
    params: query
  })
}

export function wms_common_warehouse_outflow_create (data) {
  return request({
    url: requestApi.wms_common_warehouse_outflow_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function wms_common_warehouse_outflow_partial_update (data, warehouse_id) {
  return request({
    url: requestApi.wms_common_warehouse_outflow_partial_update + warehouse_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function wms_common_warehouse_outflow_delete (warehouse_id) {
  return request({
    url: requestApi.wms_common_warehouse_outflow_delete + warehouse_id + '/',
    method: 'delete'
  })
}
