<template>
  <a-modal
    title="公司盘存"
    :width="visible_list_style? 1080: 580"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="() => { $emit('cancel') }"
    @ok="() => { $emit('ok') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="仓库" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            style="width: 100%"
            :filter-option="false"
            :not-found-content="warehouse_fetching ? undefined : null"
            show-search
            allow-clear
            v-decorator="['warehouse_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            placeholder="请输入仓库"
            :default-active-first-option="false"
            @change="handleWarehouseChange"
            @search="this.warehouseSearch"
          >
            <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in warehouseList" :key="d.id" :value="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="公司" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            style="width: 100%"
            show-search
            allow-clear
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            v-decorator="['corporation_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            placeholder="请输入公司"
            @change="handleCorporationChange"
            @search="this.corporationSearch"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in corporationList" :key="d.id" :value="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { STable } from '@/components'

import { warehouse_list } from '@/api/c_wms_warehouse'
import { corporation_list } from '@/api/corporation'
import debounce from 'lodash/debounce'
export default {
  name: 'CorporationTakeInventoryForm',
  components: {
    STable
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visible_list_style: false,
      warehouseList: [],
      dataSource_wms_cargo_space: [],
      corporationList: [],
      form: this.$form.createForm(this),
      warehouse_fetching: false,
      fetching: false,
      loading: false
    }
  },
  created () {
    this.handleWarehouseSearch(undefined)
    this.handleCorporationSearch(undefined)
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseSearch (value) {
      this.warehouseList = []
      this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.warehouseList = result
      }).finally(() => {
        this.warehouse_fetching = false
      })
    },
    handleWarehouseChange (value) {
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
    },
    handleCorporationSearch (value) {
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.corporationList = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleCorporationChange (value) {
      if (value === undefined) {
        this.handleCorporationSearch(undefined)
      }
    }
  }
}
</script>
