<template>
  <a-modal
    :title="visible_list_style?'盘存结果':'更新状态'"
    :width="visible_list_style? 1080: 480"
    :visible="visible"
    :confirmLoading="loading"
    @cancel="cancel"
    @ok="ok"
  >
    <template #footer v-if="visible_list_style">
      <a-button key="back" @click="cancel">退出</a-button>
      <a-button key="abolish" @click="toAbolish" v-show="model.status === 1">取消盘存</a-button>
      <a-button key="submit" type="primary" @click="toOk" v-show="model.status === 1">完成</a-button>
    </template>
    <a-spin :spinning="loading" v-if="visible_list_style">
      <div>
        <div class="table-page-search-wrapper"></div>
        <s-table
          ref="table"
          size="small"
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :data="loadData"
        >
        </s-table>
      </div>
    </a-spin>
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="选择状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            placeholder="选择"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            v-decorator="['status', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option v-for="(val, key) in this.$Dictionaries.take_inventory_status" :value="key" :key="key" v-if="Number(key) !== model.status">
              {{ val }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import { wms_take_inventory_result_list } from '@/api/c_wms_period_inventory'

export default {
  name: 'TakeInventoryStatusForm',
  components: {
    STable
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visible_list_style: false,
      form: this.$form.createForm(this),
      loading: false,
      // 表头
      columns: [
        {
          title: '库位',
          dataIndex: 'cargo_space',
          ellipsis: true
        },
        {
          title: '货品条形码',
          dataIndex: 'goods_purchase',
          ellipsis: true
        },
        {
          title: '实际数量',
          dataIndex: 'current_count',
          ellipsis: true
        },
        {
          title: '差异数量',
          dataIndex: 'diff_count',
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return wms_take_inventory_result_list({ take_inventory_id: this.model.id })
          .then(res => {
            return res.data
          })
      }
    }
  },
  methods: {
    ok () {
      this.$emit('ok')
    },
    toOk () {
      this.$emit('ok', 2)
    },
    toAbolish () {
      this.$emit('ok', 3)
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
